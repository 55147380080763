.dashboard-view {
  padding: 50px 86px 0 64px;
  .dashboard-item {
    height: 60px;
    background: #404040;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding: 20px 25px 20px 38px;
    margin-bottom: 20px;

    .item-name {
      font-size: 18px;
      font-family: Arial Black;
      font-weight: 400;
      color: #00FFAB;
    }
    .item-amount {
      font-size: 18px;
      font-family: Arial Black;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}

@media only screen and (max-width: 960px) {
	.dashboard-view {
    padding: 20px 10px;

    .dashboard-item {
      padding: 20px;
  
      .item-name {
        font-size: 14px;
      }
      .item-amount {
        font-size: 14px;
      }
    }
  }
}

.invite-modal {
	padding: 16px;
	background: #3E3E3E;
	box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.42);
	border-radius: 10px;

	.invite-modal-title {
		font-size: 14px;
		font-family: Arial Black;
		font-weight: 400;
		color: #00FFAB;
	}
	.invite-modal-content {
		padding: 30px 0 23px;
		font-size: 14px;
		font-family: Poppins;
		font-weight: 300;
		color: #868686;
	}
}