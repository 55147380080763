#ido-info-view {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	.textDesc {
		margin-top: 24px;
	}
	.MuiButton-outlined.Mui-disabled{
		color: rgba(0, 0, 0, 0.26);
		background-color: rgba(0, 0, 0, 0.12);
		border-color: rgba(0, 0, 0, 0.12);
	}  
	.ido-area {
		padding: 24px 10px;
		.options {
			text-align: center;
			// .connect-button,
			.operationBtn{
			  max-width: 300px !important;
			  min-width: 240px !important;
			}
			// .operationBtn {
			// 	background: #2fb5ac;
			// 	color: #FFFFFF;
			// 	border: 1px solid #2fb5ac;
			// 	border-color: #2fb5ac;
			// 	&:hover {
			// 		background: transparent;
			// 		color: #2fb5ac;
			// 		border: 1px solid #2fb5ac;
			// 		border-color: #2fb5ac;
			// 	}
			// }
		}
	}
}