.header-container {
  margin-top: 24px;
}
.snowglobe-container {
  .form-label {
	  margin-top: 20px;
	  margin-bottom: 8px;
	  h6 {
		  font-size: 12px;
	  }
  }
  .MuiButton-root {
	  font-size: 1rem !important;
	//   color: #07004A;
  }
//   .MuiOutlinedInput-notchedOutline {
// 	  border-color: #07004A!important;
//   }
  .sliderContainer {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  margin-top: 10px;
	  .sliderDesc {
			  margin-bottom: 8px;
	  }
	  .MuiSlider-root {
		//   color: #07004A;
	  }
	  .MuiSlider-root.MuiSlider-vertical {
		  height: 256px;
	  }
	  }
	  .info {
		  height: 100%;
		  .info-item {
			  margin: 10px 0;
			  .MuiTypography-colorTextSecondary {
				  font-size: 13px;
			  }
		  }
	  }
  }