.ant-modal-content,.ant-modal-header {
	background-color: transparent!important;
}
.ant-modal-header {
	border-bottom: none!important;
	padding: 0px!important;
}
.ant-modal-title {
	font-size: 18px!important;
	color: #000000!important;
	font-family: "Archivo",-apple-system, BlinkMacSystemFont!important;
}
.ant-modal-body {
	padding: 0px!important;
}
.walletInfo-container {
	font-weight: 400;
	font-family: Arial Black;
	background-color: #3E3E3E;
	box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.42);
	border-radius: 10px;
	color: #FFF;
	padding: 20px 15px 26px;
	.clsoe-icon {
		width: 15px;
		height: 15px;
		cursor: pointer;
	}
	.walletInfo {
		margin-top: 18px;
		padding: 20px;
		background: #323232;
		border-radius: 10px;
		.box {
			// margin-top: 36px;
			padding: 20px 0;
			.walletAddress {
				margin-left: 22px;
			}
		}
		.box-l {
			width: 22%;
		}
		.box-r {
			width: 70%;

			.operations {
				.viewInExplorer {
					text-decoration: none;
					margin-left: 28px;
				}
			}
		}
		// .operations {
			// margin-left: 54px;
			.operationItem {

				.operate-text {
					font-size: 12px;
					font-family: Kano;
					font-weight: 400;
					color: #8A8A8A !important;
				}
				&:hover {
					cursor: pointer;
				}
			}
		// }
		.invite-container {
			// width: 100%;
				.invite-link {
					height: 41px;
					padding: 2px 12px;
					background: #323232;
					border: 1px solid #A7A7A7;
					border-radius: 5px;
					color: #1AE899;
					span {
						width: 60%;
						white-space: nowrap;
						overflow: hidden;
					}
					img {
						width: 15px;
						height: 17px;
						margin-left: 6px;
						cursor: pointer;
					}
			}
		}
	}
}

.logOutBtn{
	width: 76px !important;
	border: 1px solid #01FDA9 !important;
	border-radius: 2px !important;
	background: transparent !important;
	padding: 6px !important;
	font-family: 'Inter',-apple-system, BlinkMacSystemFont;
	font-size: 12px!important;
	color: #26D483 !important;
}
.buyBtn {
	width: 81px !important;
	background: linear-gradient(0deg, #08C1BA 0%, #1DEE93 100%) !important;
	border-radius: 13px !important;
	font-size: 12px !important;
	font-family: Arial Black !important;
	font-weight: 400 !important;
	color: #000000 !important;
	border: none !important;
}

@media only screen and (max-width: 1000px) {
	.walletInfo-container {
		.walletInfo {
			padding: 20px 8px;
			.box {
				margin-top: 16px;
				.walletAddress {
					margin-left: 6px;
				}
			}
			.box-l {
				width: 30%;
			}
			.box-r {
				width: 68%;

				.operations {
					justify-content: space-between;
					.viewInExplorer {
						text-decoration: none;
						margin-left: 0px;
					}
				}
			}
		}
	}
	.MuiDialog-paper {
		margin: 12px !important;
	}
}