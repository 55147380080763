.selectBox {
	position: relative;
	.selectVal {
		padding-left: 12px;
		margin-left: 6px;
		border-left: 1px solid #93AEBC;
		:hover {
			cursor: pointer;
		}
		.DownIcon {
			width: 20px;
			height: 20px;
		}
		.select-caret {
			transition: transform .3s;
			transform: rotate(180deg);
			cursor: pointer;
		}
		.MuiTypography-h4 {
			font-size: 1.1rem!important;
		}
	}
	.selectList {
		display: none;
		position: absolute;
		top: 40px;
		z-index: 999;
		background-color: #FFFFFF;
		border-radius: 4px;
		padding: 12px 0;
		.selectItem {
			padding: 0px 16px;
			:hover {
				cursor: pointer;
			}
		}
	}
	.selectListDark {
		background-color: #242426!important;
	}
}

.show {
	display: block!important;
}